/**
 * FreeOptionsTable container utilities
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { ITranslations } from '@paco_ua/pacoui';

export const DEFAULT_STRUCTURE = (t: ITranslations) => ({
  header: {
    type: 'columnTitle',
    titles: [
      { text: t('sgh.department', { textOnly: true }) },
      { text: t('sgh.scientificField', { textOnly: true }) },
      { text: t('sgh.code', { textOnly: true }) },
      { text: t('sgh.uc', { textOnly: true }) },
      //{ text: t('sgh.workload', { textOnly: true }) },
      { text: t('sgh.ects', { textOnly: true }) },
      { text: t('sgh.slots', { textOnly: true }) },
      { text: t('sgh.freeSlots', { textOnly: true }) },
    ],
  },
  rows: [],
});

export const checkHasFilters = (filterParams) => {
  if (filterParams !== null && filterParams !== undefined) {
    return Object.keys(filterParams).some((filter) => {
      return filterParams[filter].trim().length > 0;
    });
  }
  return false;
};

export const checkAreAllFiltersFilled = (filterParams) => {
  if (filterParams !== null && filterParams !== undefined) {
    return Object.keys(filterParams).every((filter) => {
      return filterParams[filter].trim().length > 0;
    });
  }
  return false;
};

export const hasSelectedUcId = (ucs, freeOption) => {
  return ucs?.some((uc) => uc?.ucId === freeOption?.id);
};
