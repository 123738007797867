/**
 * Home scene
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import React, { useEffect, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Text, Card, StatusLabel } from '@paco_ua/pacoui';

import { actions } from 'store/rootSlices';

import * as selectors from './logic/selectors';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import AuthWrapper from 'shared/components/AuthWrapper';

const SchedulesHome = ({ t, size }) => {
  const { phases, loadingPhases } = useSelector(selectors.getSchedulesHomeSlice);

  const { onMount } = actions.SchedulesHome;
  const { navigateTo } = actions.App;
  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  useEffect(() => {
    dispatch(onMount());
  }, []);

  const getPhaseDetails = (type: 'normal' | 'queue' | 'automatic' | 'manual') => {
    switch (type) {
      case 'normal':
        return (
          <>
            <Text color="plusDarkGrey" size="article">
              {t('sgh.homeDetailsPhase1and2')}
            </Text>
          </>
        );
      case 'queue':
        return (
          <>
            <Text color="plusDarkGrey" size="article">
              {t('sgh.homeDetailsPhaseQueue')}
            </Text>
          </>
        );
      case 'automatic':
        return (
          <>
            <Text color="plusDarkGrey" size="article">
              {t('sgh.homeDetailsPhaseAutomatic')}
            </Text>
          </>
        );
      case 'manual':
        return (
          <>
            <Text color="plusDarkGrey" size="article">
              {t('sgh.homeDetailsPhaseManual')}
            </Text>
          </>
        );
    }
  };

  const getPhaseCardSubTitle = (
    type: 'normal' | 'queue' | 'automatic' | 'manual',
    name: number
  ) => {
    switch (type) {
      case 'normal':
        return name === 1 ? t('sgh.homeSchedulesSelection') : t('sgh.homeSchedulesSelection_fase2');
      case 'queue':
        return t('sgh.homeFreeOptionsSignUp');
      case 'automatic':
        return t('sgh.homeAutomaticAssignment');
      case 'manual':
        return t('sgh.homeManualAssignment');
    }
  };

  const getPhaseLabel = (type: 'active' | 'finished' | 'toOpen') => {
    switch (type) {
      case 'active':
        return (
          <StatusLabel
            label={t('sgh.homeLabelActive', { textOnly: true })}
            background={theme.colors.successGreen}
          />
        );
      case 'toOpen':
        return <StatusLabel label={t('sgh.homeLabelToOpen', { textOnly: true })} />;
      case 'finished':
        return <StatusLabel label={t('sgh.homeLabelFinished', { textOnly: true })} />;
    }
  };

  const shoulShowMySchedules = useMemo(() => {
    //has had finishedPhase or has active phase
    return (
      phases.some((phase) => phase.finished === true) ||
      phases.some((phase) => phase.isActive === true)
    );
  }, [phases]);

  return (
    <PageStructure
      loading={loadingPhases}
      headerLeft={<Header title={t('routes.schedulesSelection')} />}
      expanded
      headerRight={
        <HeaderButton>
          <Button
            data-testid={`schedule_selection_button_help`}
            onClick={() => {
              dispatch(navigateTo({ key: 'schedulesGuide' }));
            }}
            leftIcon={<QuestionMarkIcon icon={faQuestionCircle} />}
            primary
          >
            <Text weight="regular">{t('generic.help', { textOnly: true })}</Text>
          </Button>

          <AuthWrapper
            roles={[
              'student',
              'superUser_FullAccess',
              'superUser_ReadOnly',
              'sgh_superUser_ReadOnly',
              'sgh_superUser_FullAccess',
              'sec_superUser_ReadOnly',
              'sec_superUser_FullAccess',
            ]}
            checkSuperUser
          >
            <>
              {phases?.some((phase) => phase?.type === 'queue' && phase?.isActive) && (
                <FreeOptionsButton
                  data-testid={`schedule_selection_button_free_options`}
                  onClick={() => {
                    dispatch(navigateTo({ key: 'createSchedule' }));
                  }}
                  primary
                >
                  {t('sgh.freeOptions', { textOnly: true })}
                </FreeOptionsButton>
              )}
            </>
          </AuthWrapper>

          {shoulShowMySchedules && (
            <AuthWrapper
              roles={[
                'student',
                'superUser_FullAccess',
                'superUser_ReadOnly',
                'sgh_superUser_ReadOnly',
                'sgh_superUser_FullAccess',
                'sec_superUser_ReadOnly',
                'sec_superUser_FullAccess',
              ]}
              checkSuperUser
            >
              <Button
                data-testid={`schedule_selection_button_created_schedules`}
                onClick={() => {
                  dispatch(navigateTo({ key: 'createdSchedules' }));
                }}
                primary
              >
                {t('sgh.myScheduleButton')}
              </Button>
            </AuthWrapper>
          )}
        </HeaderButton>
      }
      subHeader={
        phases.some((phase) => phase.isActive) ? (
          <Text weight="medium" color="primary" size="large">
            {`${t('sgh.phase', { textOnly: true })} ${
              phases.find((phase) => phase.isActive)?.name
            }`}
          </Text>
        ) : (
          <Text weight="medium" color="primary" size="large">
            {phases[phases.length - 1]
              ? `${t('sgh.mySchedule', { textOnly: true })} ${phases[phases.length - 1].year}/${
                  phases[phases.length - 1].year + 1
                }`
              : `${t('sgh.mySchedule', { textOnly: true })}`}
          </Text>
        )
      }
      mainContent={
        <>
          <PresentationWrapper>
            <Text color="darkGrey" size="article">
              {t('sgh.homePresentation1')}
            </Text>
            <Text color="darkGrey" size="article">
              {t('sgh.homePresentation2')}
            </Text>
          </PresentationWrapper>

          <ContentWrapper>
            {
              //setting this verification because of respnsive masonry Bug
              !loadingPhases && (
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 650: 2, 900: 3, 1200: 4 }}>
                  <Masonry gutter="40px">
                    {phases.map((phase, key) => {
                      const phaseType = phase.type;

                      const phaseLabel = phase.finished
                        ? 'finished'
                        : phase.isActive
                        ? 'active'
                        : 'toOpen';

                      return (
                        <Card
                          key={`schedules_home_phases_${key}`}
                          active={phase.isActive}
                          title={
                            (phase.name === 1 || phase.name === 2) ?
                          `${t('sgh.schedules_first_second_phase', { textOnly: true })}` :
                              `${t('sgh.schedules_third_phase', { textOnly: true })}`
                        }
                          headerRight={getPhaseLabel(phaseLabel)}
                          expandedContent={
                            <PhaseDetailsExpanded>
                              <PhaseDetailsExpandedHeader
                                color="plusDarkGrey"
                                weight="medium"
                                size="article"
                              >
                                {t('sgh.details')} :
                              </PhaseDetailsExpandedHeader>

                              <PhaseDetailsExpandedContent>
                                {getPhaseDetails(phaseType)}
                              </PhaseDetailsExpandedContent>
                            </PhaseDetailsExpanded>
                          }
                        >
                          <PhaseDetails>
                            <PhaseDetailsHeader>
                              <Text
                                icon={<FontAwesomeIcon size="lg" icon={faInfoCircle} />}
                                color="primary"
                                size="article"
                                weight="medium"
                              >
                                {getPhaseCardSubTitle(phaseType,phase.name)}
                              </Text>
                            </PhaseDetailsHeader>

                            <PhaseDetailsContent>
                              <Text color="plusDarkGrey" size="article" weight="medium">
                                {t('sgh.homePhaseBegin')}:&nbsp;
                                <Text color="plusDarkGrey" size="article" weight="light">
                                  {t('generic.dateTime', {
                                    date: moment(phase.beginDate).format('DD/MM/YYYY'),
                                    time: moment(phase.beginDate).format('HH:mm'),
                                  })}{' '}
                                </Text>
                              </Text>

                              <Text color="plusDarkGrey" size="article" weight="medium">
                                {t('sgh.homePhaseEnd')}:&nbsp;
                                <Text color="plusDarkGrey" size="article" weight="light">
                                  {t('generic.dateTime', {
                                    date: moment(phase.endDate).format('DD/MM/YYYY'),
                                    time: moment(phase.endDate).format('HH:mm'),
                                  })}{' '}
                                </Text>
                              </Text>

                              <Text color="plusDarkGrey" size="article" weight="medium">
                                {t('sgh.homePhaseAllocations')}:&nbsp;
                                <Text color="plusDarkGrey" size="article" weight="light">
                                  {t('generic.dateTime', {
                                    date: moment(phase.seriationDate).format('DD/MM/YYYY'),
                                    time: moment(phase.seriationDate).format('HH:mm'),
                                  })}
                                </Text>
                              </Text>
                            </PhaseDetailsContent>
                          </PhaseDetails>
                        </Card>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              )
            }

            <LinksWrapper>
              <Links>
                <LinkArea>
                  <Link color="darkGrey" weight="light">
                    {`${t('sgh.homeMoreIngoAtSga', { textOnly: true })}`}
                  </Link>
                  &nbsp;
                  <Text as="a" href={t('links.schedulesHome', { textOnly: true })} target="_blank">
                    {t('links.schedulesHome', { textOnly: true })}
                  </Text>
                </LinkArea>

                <LinkArea>
                  <Link color="darkGrey" weight="light">
                    {`${t('sgh.homeHelpGuide', { textOnly: true })}`}
                  </Link>
                  &nbsp;
                  <Text
                    as="a"
                    onClick={() => {
                      dispatch(navigateTo({ key: 'schedulesGuide' }));
                    }}
                    target="_blank"
                  >
                    {t('sgh.homeHelpGuideView', { textOnly: true })}
                  </Text>
                </LinkArea>

                {/*<LinkArea>
                  <Link color="darkGrey" weight="light">
                    {`${t('sgh.homeHelpPersonalizedSupport', { textOnly: true })}`}
                  </Link>
                  &nbsp;
                  <Text as="a" href={t('links.helpdesk', { textOnly: true })} target="_blank">
                    {t('links.helpdesk', { textOnly: true })}
                  </Text>
                </LinkArea>*/}

                <LinkArea>
                  <Link color="darkGrey" weight="light">
                    {`${t('sgh.homeHelpTechnicalSupport', { textOnly: true })}`}
                  </Link>
                  &nbsp;
                  <Text
                    as="a"
                    href={t('links.homeHelpTechnicalSupport', { textOnly: true })}
                    target="_blank"
                  >
                    {t('sgh.accessForm')}
                  </Text>
                </LinkArea>
              </Links>
            </LinksWrapper>
          </ContentWrapper>
        </>
      }
    />
  );
};

export default SchedulesHome;

const HeaderButton = styled.div`
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 44px;
`;

const PresentationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const QuestionMarkIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
`;

const LinksWrapper = styled.div`
  margin-top: 78px;
`;

const Links = styled.div`
  overflow-wrap: anywhere;

  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const LinkArea = styled.div``;

const Link = styled(Text)`
  /*text-decoration: underline;*/
`;

const PhaseDetails = styled.div``;

const PhaseDetailsHeader = styled.div`
  margin-bottom: 26px;
`;

const PhaseDetailsContent = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const PhaseDetailsExpanded = styled.div``;

const PhaseDetailsExpandedHeader = styled(Text)`
  margin-bottom: 18px;
`;

const PhaseDetailsExpandedContent = styled.div`
  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
`;

const FreeOptionsButton = styled(Button)`
  padding: 0px 12px;
`;
