/**
 * FreeOptionsList container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';
import { AnimatedBackground } from '@paco_ua/pacoui';

import FreeOptionsTableStructure from './structure';
import { checkAreAllFiltersFilled, checkHasFilters } from './utils';

const FreeOptionsTable: React.FC<{
  t;
  onLoad;
  onError;
  onPeriod;
  registrationId?: number | null;
  mandatoryFilters?: boolean;
  onSelectRow?: (any) => void;
}> = ({ t, onLoad, onError, onPeriod, mandatoryFilters, registrationId, onSelectRow }) => {
  const {
    onMount,
    setSearchFilter,
    setDepartmentFilter,
    setCycleFilter,
    fetchFreeOptions,
    onUnmount,
  } = actions.FreeOptionsTable;

  const { currentLocale } = useSelector(selectors.getAppSlice);

  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  const {
    loadingStatus,
    errorStatus,
    filterParams,
    freeOptionsList,
    freeOptionsPage,
    departmentFilterList,
    cycleFilterList,
    year,
    semester,
  } = useSelector(selectors.getFreeOptionsListSlice);

  const ucs = useSelector(selectors.getUcs);

  useEffect(() => {
    dispatch(onMount({ registrationId, mandatoryFilters }));
    return () => {
      dispatch(onUnmount());
    };
  }, [currentLocale]);

  useEffect(() => {
    onPeriod && onPeriod({ year, semester });
  }, [year, semester]);

  useEffect(() => {
    onLoad && onLoad(loadingStatus.loading);
  }, [loadingStatus]);

  useEffect(() => {
    onError && onError(errorStatus.errLoadingFreeOptions);
  }, [errorStatus]);

  return (
    <>
      {loadingStatus.loading && (
        <LoadingWrapper>
          <AnimatedBackground height={theme.sizes.large} width="20%" />
          <AnimatedBackground height={theme.sizes.large} width="50%" />
          <AnimatedBackground height={theme.sizes.large} width="80%" />
          <AnimatedBackground height={theme.sizes.large} width="80%" />
          <AnimatedBackground height={theme.sizes.large} width="80%" />
          <AnimatedBackground height={theme.sizes.large} width="80%" />
          <AnimatedBackground height={theme.sizes.large} width="50%" />
        </LoadingWrapper>
      )}
      {!loadingStatus.loading && !errorStatus.errLoadingFreeOptions && (
        <FreeOptionsTableStructure
          t={t}
          mandatoryFilters={mandatoryFilters}
          disableFilters={loadingStatus.loadingTable}
          loading={loadingStatus.loadingTable}
          fetchFreeOptions={(params) => {
            dispatch(fetchFreeOptions({ ...params, mandatoryFilters, registrationId }));
          }}
          addFilters={{
            searchPlaceholder: t('sgh.searchFreeOptionPlaceholder', { textOnly: true }),
            dropdownDepartmentPlaceholder: t('sgh.freeOptionsDropdownDepartmentPlaceholder', {
              textOnly: true,
            }),
            dropdownCoursePlaceholder: t('sgh.freeOptionsDropdownCoursePlaceholder', {
              textOnly: true,
            }),
            onSearch: (value) => {
              dispatch(setSearchFilter(value));
            },
            onCycleFilterChange: (value) => {
              dispatch(setCycleFilter(value));
            },
            onDepartmentFilterChange: (value) => {
              dispatch(setDepartmentFilter(value));
            },
            filterDepartments: departmentFilterList,
            filterCycles: cycleFilterList,
          }}
          filterParams={filterParams}
          total={freeOptionsPage.totalRecords}
          data={freeOptionsList}
          registrationId={registrationId}
          onSelectRow={onSelectRow}
          ucs={ucs}
        />
      )}
    </>
  );
};

export default FreeOptionsTable;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  margin-top: 20px;

  * {
    margin: 8px 0px;
  }
`;
