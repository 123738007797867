/**
 * FreeOptionsList container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useMemo } from 'react';
import moment from 'moment';

import { Table, Button, Text, SearchBox, Dropdown, ITranslations } from '@paco_ua/pacoui';
import styled from 'styled-components';
import { actions } from 'store/rootSlices';

import { IFreeOption } from './types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

import {
  getIrregularMessageFreeOptions,
  getIrregularMessageSmallFreeOptions,
} from '../../MySchedule/utils';

import { checkAreAllFiltersFilled, checkHasFilters, hasSelectedUcId } from './utils';

import useTable from './useTable';

const compileTableRow = (
  freeOption: IFreeOption,
  theme,
  t: ITranslations,
  registrationId,
  onSelectRow,
  dispatch,
  ucs
) => {
  let tableRow = {
    rowProps: {},
    cells: [
      ...Object.keys(freeOption)
        .filter((key) =>
          ['department', 'scientificField', 'id', 'name', 'ects', 'totalSlots', 'freeSlots'].some(
            (item) => key === item
          )
        )
        .map((key, index) => {
          let width = 1;
          if (key === 'name') {
            width = 5;
          } else if (key === 'scientificField' || key === 'id' || key === 'workload') {
            width = 2;
          } else {
            width = 1;
          }

          if (key === 'workload') {
            return {
              content: (
                <ContentWrapper>
                  <Text size="article" weight="regular" color={'plusDarkGrey'}>
                    {`${freeOption[key].totalHours}h / ${t('sgh.week', { textOnly: true })}`}
                  </Text>
                </ContentWrapper>
              ),
              cellProps: { width: width, singleLine: false },
            };
          } else {
            return {
              content: (
                <ContentWrapper
                  onClick={(e) => {
                    if (key === 'name') e.stopPropagation();
                  }}
                >
                  <Text
                    as={key === 'name' && 'a'}
                    href={
                      key === 'name' &&
                      t('links.ucDetailsPortal', { uc: freeOption.id, textOnly: true })
                    }
                    target="_blank"
                    size="article"
                    weight={
                      key === 'name' ||
                      key === 'department' ||
                      key === 'scientificField' ||
                      key === 'id'
                        ? 'medium'
                        : 'regular'
                    }
                    color={key === 'name' ? 'primary' : 'plusDarkGrey'}
                  >
                    {freeOption[key] === null || freeOption[key] === undefined ? (
                      <FontAwesomeIcon icon={faEllipsisH} />
                    ) : (
                      freeOption[key]
                    )}
                  </Text>
                </ContentWrapper>
              ),
              cellProps: {
                width: width,
                singleLine: false,
              },
            };
          }
        }),
    ],
    collapsableRows: registrationId
      ? []
      : [
          {
            rowProps: {
              bgColor: theme.colors.softGrey,
              disableHover: true,
            },
            cells: Object.keys(freeOption)
              .filter((key) => key === 'availableAt' || key === 'classes')
              .map((key) => {
                /*if (key === 'workload') {
              const workloadList = freeOption.workload.classHours.map((item) => {
                return (
                  <Text color="plusDarkGrey" size="article" weight="regular">
                    {`${item.type} - ${item.hours}h / ${t('sgh.week', { textOnly: true })}`}
                  </Text>
                );
              });
              return {
                content: (
                  <WorkloadWrapper>
                    <Text color="primary" size="article" weight="regular">
                      {`${t('sgh.workload', { textOnly: true })}`}
                    </Text>
                    {workloadList.map((item) => item)}
                  </WorkloadWrapper>
                ),
                cellProps: { colSpan: 3, singleLine: false },
              };
            }*/
                if (key === 'availableAt') {
                  const availableAtList = freeOption.availableAt.map((item) => {
                    return (
                      <Text color="plusDarkGrey" size="article" weight="regular">
                        {item}
                      </Text>
                    );
                  });
                  return {
                    content: (
                      <WorkloadWrapper>
                        <Text color="primary" size="article" weight="regular">
                          {`${t('sgh.availableAt', { textOnly: true })}`}
                        </Text>
                        {availableAtList?.map((item) => item) || (
                          <FontAwesomeIcon icon={faEllipsisH} />
                        )}
                      </WorkloadWrapper>
                    ),
                    cellProps: { colSpan: 3, singleLine: false },
                  };
                } else {
                  // classes
                  let classesList = [] as any;
                  freeOption.classes.map((item, index) => {
                    if (item.lessons.length > 0) {
                      item.lessons
                        .map((lessonItem) => ({
                          ...lessonItem,
                          text: `${
                            moment.weekdays()[moment(lessonItem.startDate).day()]
                          } | ${moment(lessonItem.startDate).format('HH:mm')} - ${moment(
                            lessonItem.endDate
                          ).format('HH:mm')}`,
                        }))
                        .reduce((acc: any, item) => {
                          if (!acc.some((accLessonItem) => accLessonItem.text === item.text)) {
                            acc.push(item);
                          }
                          return acc;
                        }, [])
                        .forEach((lessonItem) => {
                          classesList.push(
                            <div key={`${index}classList`}>
                              <Text color="plusDarkGrey" size="article" weight="medium">
                                {item.name}
                              </Text>
                              <Text color="plusDarkGrey" size="article" weight="regular">
                                {` - ${lessonItem.text}`}
                              </Text>
                            </div>
                          );
                        });
                    }
                  });

                  return {
                    content: (
                      <ClassesWrapper>
                        <Text color="primary" size="article" weight="regular">
                          {`${t('sgh.classes', { textOnly: true })}`}
                        </Text>
                        {classesList.length === 0 && <FontAwesomeIcon icon={faEllipsisH} />}
                        {classesList.map((item) => item)}
                      </ClassesWrapper>
                    ),
                    cellProps: { colSpan: 6, singleLine: false },
                  };
                }
              }),
          },
        ],
  };

  if (registrationId) {
    tableRow.cells.push({
      content: (
        <ContentWrapper>
          {freeOption?.irregular ? (
            <Text
              icon={
                <FontAwesomeIcon
                  onClick={() => {
                    dispatch(
                      actions.Toaster.showToaster({
                        title: getIrregularMessageSmallFreeOptions(freeOption),
                        body: getIrregularMessageFreeOptions(freeOption),
                        icon: 'error',
                        type: 'danger',
                      })
                    );
                  }}
                  cursor="pointer"
                  size="lg"
                  color={theme.colors.dangerRed}
                  icon={faExclamationCircle}
                />
              }
              color="red"
            >
              {getIrregularMessageSmallFreeOptions(freeOption)}
            </Text>
          ) : hasSelectedUcId(ucs, freeOption) ? (
            <Text
              icon={
                <FontAwesomeIcon
                  onClick={() => {
                    dispatch(
                      actions.Toaster.showToaster({
                        title: t('sgh.irregularSituationFreeOptionAlreadySelectedSmall'),
                        body: t('sgh.irregularSituationFreeOptionAlreadySelected'),
                        icon: 'error',
                        type: 'danger',
                      })
                    );
                  }}
                  cursor="pointer"
                  size="lg"
                  color={theme.colors.dangerRed}
                  icon={faExclamationCircle}
                />
              }
              color="red"
            >
              {t('sgh.irregularSituationFreeOptionAlreadySelectedSmall')}
            </Text>
          ) : (
            <Button
              onClick={() => {
                onSelectRow && onSelectRow(freeOption);
              }}
              primary
              full
            >
              {t('generic.addElement', {
                what: t('sgh.uc', { textOnly: true }),
              })}
            </Button>
          )}
        </ContentWrapper>
      ),
      cellProps: {
        width: 2,
        singleLine: false,
      },
    });
  }
  return tableRow;
};

const FreeOptionsTable = ({
  t,
  loading,
  disableFilters,
  data,
  total,
  fetchFreeOptions,
  addFilters,
  filterParams,
  registrationId,
  onSelectRow,
  mandatoryFilters,
  ucs,
}) => {
  const { structure, pagination, onChangePage, onChangeRows, theme } = useTable(
    t,
    data,
    compileTableRow,
    total,
    fetchFreeOptions,
    filterParams,
    registrationId,
    onSelectRow,
    mandatoryFilters,
    ucs
  );

  const hasFilter = useMemo(() => {
    return checkHasFilters(filterParams);
  }, [filterParams]);

  const hasMissingFilter = useMemo(() => {
    return !checkAreAllFiltersFilled(filterParams);
  }, [filterParams]);

  return (
    <>
      <SearchAndFiltersWrapper>
        <SearchBox
          disabled={loading}
          placeholder={addFilters.searchPlaceholder}
          borderColor={theme.colors.plusDarkGrey}
          iconColor={theme.colors.plusDarkGrey}
          useMininumLetters
          minumumLetters={3}
          delay={2000}
          onSearch={(value) => {
            addFilters.onSearch(value);
          }}
        />
        <Dropdown
          placeholder={addFilters.dropdownDepartmentPlaceholder}
          canBeCleared
          disable={disableFilters || loading}
          options={addFilters.filterDepartments}
          selectionWeight={'400'}
          selectionFontSize={'medium'}
          onChange={(e, { value }) => {
            addFilters.onDepartmentFilterChange(value);
          }}
          onClear={() => {
            addFilters.onDepartmentFilterChange('');
          }}
        />
        <Dropdown
          placeholder={addFilters.dropdownCoursePlaceholder}
          canBeCleared
          disable={disableFilters || loading}
          options={addFilters.filterCycles}
          selectionWeight={'400'}
          selectionFontSize={'medium'}
          onChange={(e, { value }) => {
            addFilters.onCycleFilterChange(value);
          }}
          onClear={() => {
            addFilters.onCycleFilterChange('');
          }}
        />
      </SearchAndFiltersWrapper>

      {mandatoryFilters && !hasFilter &&  (
        <MessageNoOptions>
          <Icon>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Icon>
          <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
            {t('generic.selectFilterInfo', { textOnly: true })}
          </Text>
        </MessageNoOptions>
      )}
      {mandatoryFilters && hasFilter && hasMissingFilter && (
        <MessageNoOptions>
          <Icon danger={true}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Icon>
          <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
            {t('generic.fillAllFilters', { textOnly: true })}
          </Text>
        </MessageNoOptions>
      )}


      {((mandatoryFilters && hasFilter) || !mandatoryFilters) && (
        <>
          {(data.length > 0 || loading) && (
            <FreeOptionsTableWrapper loading={loading || data.length === 0}>
              <Table
                unstackable
                structure={structure}
                loading={loading}
                pagination={pagination}
                onChangeRows={onChangeRows}
                onChangePage={onChangePage}
                stackingFrom={992}
                translations={t}
              />
            </FreeOptionsTableWrapper>
          )}

          {data.length === 0 && !loading && !hasMissingFilter && (
            <MessageNoOptions>
              <Icon>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Icon>
              <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                {t('sgh.noOptionsListFound', { textOnly: true })}
              </Text>
            </MessageNoOptions>
          )}
        </>
      )}
    </>
  );
};

export default FreeOptionsTable;

const ContentWrapper = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'None')};
  margin: 26px 0px 26px 0px;
  width: fit-content;
`;

const WorkloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;

  margin: 20px 0px 20px 0px;

  > div:first-child {
    margin-bottom: 8px;
  }

  > div:not(:first-child):not(:last-child) {
    margin-bottom: 4px;
  }
`;

const ClassesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;

  margin: 20px 0px 20px 0px;

  > div:first-child {
    margin-bottom: 8px;
  }

  > div:not(:first-child):not(:last-child) {
    margin-bottom: 4px;
  }
`;

const FreeOptionsTableWrapper = styled.div<{ loading: boolean }>`
  &&& th {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    vertical-align: ${({ loading }) => (loading ? 'None' : 'top')};
    height: ${({ loading }) => (loading ? '76px' : 'None')};
  }

  &&& td:last-child > svg {
    margin-top: 28px;
  }

  &&& .ui.table tfoot tr td {
    height: 45px;
  }
`;

const SearchAndFiltersWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 24px;
  justify-content: flex-start;

  > div {
    width: 20%;
    min-width: 268px;
    max-width: 386px;
  }

  > div:not(:first-child) {
    margin-left: 16px;
  }
`;

const MessageNoOptions = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const Icon = styled.div<{ danger?: boolean}>`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};

  ${({ danger, theme}) => {
    if(danger){
      return `
        svg {
          path {
            fill: ${theme.colors.dangerRed} !important;
          } 
        }
      `
    }
  }};
`;