import config from 'config'

const targets: any[] = [config.IFRAME_LOGS, config.IFRAME_ENROLL];
const origins: any[] = [config.IFRAME_LOGS_ORIGIN, config.IFRAME_LOGS, config.IFRAME_ENROLL,config.IFRAME_ENROLL_ORIGIN];

const checkIfOriginIsValid = (origin: string | null | undefined) => {
  return origins.some((messageOrigin) => messageOrigin === origin);
};

const checkIfTargetisValid = (target: string | null | undefined) => {
  return targets.some((targetWindow) => targetWindow === target);
};

export { checkIfTargetisValid, checkIfOriginIsValid };
