/**
 * FreeOptionsList scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { Text } from '@paco_ua/pacoui';

import numeral from 'numeral';

import FreeOptionsTable from 'scenes/Schedules/containers/FreeOptionsTable';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

//import config from 'config'

const FreeOptionsList = ({ t }) => {
  const { setError, setLoading, setPeriod } = actions.FreeOptionsList;

  const dispatch = useDispatch();

  const { loading, error, year, semester } = useSelector(selectors.getFreeOptionsListSlice);

  return (
    <PageStructure
      expanded
      loading={loading || error}
      headerLeft={<Header backButton title={t('sgh.freeOptionsList')} />}
      subHeader={
        year !== null &&
        semester !== null && (
          <Text color="plusDarkGrey" size="large" fontWeight="400">
            {`${year}/${year + 1} - ${`${numeral(semester).format('o')}`} ${t('sgh.semester', {
              textOnly: true,
            })}`}
          </Text>
        )
      }
      mainContent={
        <ContentWrapper>
          <FreeOptionsTable
            mandatoryFilters
            t={t}
            onLoad={(_loading) => {
              dispatch(setLoading(_loading));
            }}
            onError={(_error) => {
              dispatch(setError(_error));
            }}
            onPeriod={(period) => {
              dispatch(setPeriod(period));
            }}
          />
        </ContentWrapper>
      }
    />
  );
};

export default FreeOptionsList;

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
